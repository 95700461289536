@media only screen and (max-width: 468px) {
  .card-grid {
    width: 100% !important;
  }

  .userdetail-box {
    width: calc(100% + 48px) !important;
    margin-left: -24px !important;
  }

  .skeleton-responsive {
    width: 100% !important;
  }
}
